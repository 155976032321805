// import { FormikStepper, FormikStep, InputField } from "formik-stepper";
// import { Field } from "formik";
// import "formik-stepper/dist/style.css";
// import axios from "axios";
// import { config } from "./Constant";

// import { IndustryOptions } from "./IndustryOptions";
// import { Container, Row, Col, Toast, ToastContainer } from "react-bootstrap";
// import { useNavigate } from "react-router-dom";
// import { useDispatch, useSelector } from "react-redux";
// import { setUserID } from "../actions";
// import { useState, useEffect } from "react";
// const ToastComp = ({ show, onClose }) => {
//   return (
//     <ToastContainer>
//       <Toast show={show.show} delay={3000} autohide onClose={onClose}>
//         <Toast.Header>
//           <strong className="me-auto">ALERT</strong>
//         </Toast.Header>
//         <Toast.Body>{show.msg}</Toast.Body>
//       </Toast>
//     </ToastContainer>
//   );
// };

// const Form = () => {
//   const navigate = useNavigate();
//   const [show, setShow] = useState({ show: false, msg: "" });

//   const toggleShow = () => setShow({ show: false, msg: "" });
//   const user = useSelector((state) => state.user);

//   const [fileUpload, setFileUpload] = useState(null);
//   const [preview, setPreview] = useState();
//   const dispatch = useDispatch();

//   useEffect(() => {
//     if (!fileUpload) {
//       setPreview(undefined);
//       return;
//     }

//     const objectUrl = URL.createObjectURL(fileUpload);
//     setPreview(objectUrl);

//     // free memory when ever this component is unmounted
//     return () => URL.revokeObjectURL(objectUrl);
//   }, [fileUpload]);

//   const handleGoals = (e) => {
//     e.preventDefault();
//     var target = e.target;

//     var parent = target.parentElement; //parent of "t
//     if (e.target.checked) {
//       parent.classList.add("selected");
//     } else {
//       parent.classList.remove("selected");
//     }
//   };

//   function loadScript(src) {
//     return new Promise((resolve) => {
//       const script = document.createElement('script');
//       script.src = src;
//       script.onload = () => {
//         resolve(true);
//       };
//       script.onerror = () => {
//         resolve(false);
//       };
//       document.body.appendChild(script);
//     });
//   }

//   async function displayRazorpay() {
//     const res = await loadScript(
//       'https://checkout.razorpay.com/v1/checkout.js'
//     );

//     if (!res) {
//       alert('Razorpay SDK failed to load. Are you online?');
//       return;
//     }
//     else{
//       console.log(res,'loadingggggg')
//     }

//     const result = await axios.post('http://localhost:8000/brand/payment');

//     if (!result) {
//       alert('Server error. Are you online?');
//       return;
//     }

//     const { amount, id: order_id, currency } = result.data;

//     const options = {
//       key: 'rzp_live_qTUOVmZMoxYeMC', // Enter the Key ID generated from the Dashboard
//       amount: amount.toString(),
//       currency: currency,
//       name: 'Soumya Corp.',
//       description: 'Test Transaction',

//       order_id: order_id,
//       handler: async function (response) {
//         const data = {
//           orderCreationId: order_id,
//           razorpayPaymentId: response.razorpay_payment_id,
//           razorpayOrderId: response.razorpay_order_id,
//           razorpaySignature: response.razorpay_signature,
//         };

//         const result = await axios.post('http://localhost:8000/brand/success', data);

//         alert(result.data.msg);
//       },
//       prefill: {
//         name: '<YOUR NAME>',
//         email: 'example@example.com',
//         contact: '9999999999',
//       },
//       notes: {
//         address: 'Example Corporate Office',
//       },
//       theme: {
//         color: '#61dafb',
//       },
//     };

//     const paymentObject = new window.Razorpay(options);
//     paymentObject.open();
//   }

//   const onSubmit = async (values, { setSubmitting }) => {
//     if (fileUpload != null) {
//       try {

//         displayRazorpay()

//       } catch (err) {
//         setShow({
//           show: true,
//           msg: "Facing An Error ",
//         });
//       }
//     } else {
//       setShow({
//         show: true,
//         msg: "Please upload Image",
//       });
//     }
//   };

//   return (
//     <FormikStepper
//       onSubmit={onSubmit}
//       initialValues={{
//         marketing_budget: "Less than 2 lacs",
//         marketing_goals: ["Brand Awareness"],
//         IsStock_image: "Yes",
//         IsLogo: "Yes",
//       }}
//       withStepperLine={false}
//       nextButton={{ label: "Next" }}
//       prevButton={{ label: "Back" }}
//     >
//       <FormikStep>
//         <Container>
//           <Row className="justify-content-center">
//             <Col md={6} sm={12}>
//               <div className="form-header-content">
//                 <span>Step 1 of 3</span>
//                 <h2 className="stepper-form-heading">
//                   What is your estimated monthly marketing budget?
//                 </h2>
//                 <h6>Select an estimated range</h6>
//               </div>
//               <div
//                 role="group"
//                 className="estimation"
//                 aria-labelledby="my-radio-group"
//               >
//                 <label className="selected">
//                   <Field
//                     type="radio"
//                     name="marketing_budget"
//                     value="Less than 2 lacs"
//                   />
//                   Less than 2 lacs
//                 </label>
//                 <label>
//                   <Field
//                     type="radio"
//                     name="marketing_budget"
//                     value="2 lacs - 8 lacs"
//                   />
//                   2 lacs - 8 lacs
//                 </label>
//                 <label>
//                   <Field
//                     type="radio"
//                     name="marketing_budget"
//                     value="8 lacs - 50 lacs "
//                   />
//                   8 lacs - 50 lacs
//                 </label>
//                 <label>
//                   <Field
//                     type="radio"
//                     name="marketing_budget"
//                     value="50 lacs & above"
//                   />
//                   50 lacs & above
//                 </label>
//                 <label>
//                   <Field
//                     type="radio"
//                     name="marketing_budget"
//                     value="Preferred not to disclose"
//                   />
//                   Preferred not to disclose
//                 </label>
//               </div>
//             </Col>
//           </Row>
//         </Container>
//       </FormikStep>
//       <FormikStep>
//         <ToastComp show={show} onClose={toggleShow} />
//         <Container>
//           <Row className="justify-content-center">
//             <Col md={6} sm={12}>
//               <div className="form-header-content">
//                 <span>Step 2 of 3</span>
//                 <h2>What are your primary content marketing goals?</h2>
//                 <h6>Select your goals</h6>
//               </div>
//               <div className="form-goals">
//                 <div
//                   role="group"
//                   aria-labelledby="checkbox-group"
//                   onChange={(e) => handleGoals(e)}
//                 >
//                   <label className="selected">
//                     <Field
//                       type="checkbox"
//                       name="marketing_goals"
//                       value="Brand Awareness"
//                     />
//                     Brand Awareness
//                   </label>
//                   <label>
//                     <Field
//                       type="checkbox"
//                       name="marketing_goals"
//                       value="Lead Generation"
//                     />
//                     Lead Generation
//                   </label>
//                   <label>
//                     <Field
//                       type="checkbox"
//                       name="marketing_goals"
//                       value="Customer Loyalty"
//                     />
//                     Customer Loyalty
//                   </label>
//                 </div>
//               </div>

//               <div className="form-campaign">
//                 <div className="form-header-content">
//                   <h2>Enter Campaign Details</h2>
//                 </div>
//                 <InputField
//                   name="brand_name"
//                   placeholder="Brand Name"
//                   type="text"
//                 />
//                 <InputField
//                   name="campaign_name"
//                   placeholder="Campaign Name"
//                   type="text"
//                   required
//                 />
//                 <div className="file-upload ">
//                   <div>
//                     <p>Upload Logo</p>
//                     <input
//                       type="file"
//                       onChange={(event) => {
//                         const file = event.target.files[0];
//                         const maxSize = 5 * 1024 * 1024; // 5MB in bytes

//                         if (file && file.size <= maxSize) {
//                           setFileUpload(file);

//                           // Optional: Show a preview if it's an image
//                           const reader = new FileReader();
//                           reader.onload = () => {
//                             setPreview(reader.result);
//                           };
//                           reader.readAsDataURL(file);
//                         } else {
//                           alert("File size exceeds 5MB limit");
//                           event.target.value = null; // Reset the input field
//                         }
//                       }}
//                       required
//                     />
//                   </div>
//                   <div>{preview && <img src={preview} alt="Preview" />}</div>
//                 </div>

//                 <Field
//                   as="select"
//                   name="campaign_industry"
//                   placeholder="Choose the industry of your project"
//                 >
//                   <option value={""} disabled selected>
//                     Choose the industry of your project
//                   </option>
//                   {IndustryOptions.map((i) => (
//                     <option value={i.value}>{i.value}</option>
//                   ))}
//                 </Field>
//                 <Field
//                   name="target_audience"
//                   as="textarea"
//                   placeholder="What’s your target audience?"
//                 ></Field>
//               </div>
//               <div className="form-logo">
//                 <p>Do you need your logo on design?</p>

//                 <div role="group" aria-labelledby="my-radio-group">
//                   <label>
//                     <Field type="radio" name="IsLogo" value="Yes" />
//                     Yes
//                   </label>
//                   <label>
//                     <Field type="radio" name="IsLogo" value="No" />
//                     No
//                   </label>
//                 </div>
//                 <p>Can Designer Use Stock Images?</p>
//                 <div role="group" aria-labelledby="my-radio-group">
//                   <label>
//                     <Field type="radio" name="IsStock_image" value="Yes" />
//                     Yes
//                   </label>
//                   <label>
//                     <Field type="radio" name="IsStock_image" value="No" />
//                     No
//                   </label>
//                 </div>
//                 <Field
//                   name="brand_guidlines"
//                   as="textarea"
//                   placeholder="Brand Guidelines"
//                 ></Field>
//               </div>
//             </Col>
//           </Row>
//         </Container>
//       </FormikStep>
//       <FormikStep>
//         <Container>
//           <Row className="justify-content-center">
//             <Col md={6} sm={12}>
//               <div className="form-header-content">
//                 <span>Step 3 of 3</span>
//                 <h2>Which Meme formats are you looking for?</h2>
//               </div>
//               <div className="form-meme">
//                 <InputField
//                   name="static_meme"
//                   label="Static Meme"
//                   placeholder="Quantity *200"
//                   type="number"
//                   min="1"
//                 />
//                 <InputField
//                   name="gif"
//                   label="Gif Meme"
//                   type="number"
//                   placeholder="Quantity *300"
//                   min="1"
//                 />
//                 <InputField
//                   name="video_meme"
//                   label="Video Meme"
//                   type="number"
//                   placeholder="Quantity *500"
//                   min="1"
//                 />
//               </div>

//               <div className="form-campaign " style={{ marginTop: 50 }}>
//                 <InputField
//                   name="time_limit"
//                   label="Time Limit"
//                   type="number"
//                   placeholder="No. of Days"
//                   min="1"
//                 />
//               </div>
//             </Col>
//           </Row>
//         </Container>
//       </FormikStep>
//     </FormikStepper>
//   );
// };

// export const StepperForm = () => {
//   return (
//     <div className="stepper-form">
//       <Container>
//         <Row>
//           <Col sm={12}>
//             <Form />
//           </Col>
//         </Row>
//       </Container>
//     </div>
//   );
// };

import { FormikStepper, FormikStep, InputField } from "formik-stepper";
import RazorpayButton from "./RazorpayButton";
import { Field } from "formik";
import "formik-stepper/dist/style.css";
// import * as Yup from "yup";
import axios from "axios";
import { config } from "./Constant";

import { IndustryOptions } from "./IndustryOptions";
import { Container, Row, Col, Toast, ToastContainer } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { setUserID } from "../actions";
import { useState, useEffect } from "react";
const ToastComp = ({ show, onClose }) => {
  return (
    <ToastContainer>
      <Toast show={show.show} delay={3000} autohide onClose={onClose}>
        <Toast.Header>
          <strong className="me-auto">ALERT</strong>
        </Toast.Header>
        <Toast.Body>{show.msg}</Toast.Body>
      </Toast>
    </ToastContainer>
  );
};

const Form = () => {
  const navigate = useNavigate();
  const [show, setShow] = useState({ show: false, msg: "" });

  const toggleShow = () => setShow({ show: false, msg: "" });
  const user = useSelector((state) => state.user);

  const [fileUpload, setFileUpload] = useState(null);
  const [preview, setPreview] = useState();
  const dispatch = useDispatch();

  const handlePayment = async (amount,values) => {
    try {
      const response = await axios.post(
        `${config.API_URL}/brand/create-order`,
        {
          amount,
          currency: "INR",
        }
      );

      const { id: order_id } = response.data;

      const options = {
        key: "rzp_live_qTUOVmZMoxYeMC",
        amount, // amount in the smallest currency unit (50000 paise = 500 INR)
        currency: "INR",
        name: "Marqueberry",
        description: "Brand Campaign",
        image: "https://marqueberry.com/favicon.ico",
        order_id: order_id,
        handler: async (response) => {
          // Razorpay payment successful response object
          const { razorpay_order_id, razorpay_payment_id, razorpay_signature } =
            response;

          try {
            const paymentResult = await axios.post(
              `${config.API_URL}/brand/verify-payment`,
              {
                orderCreationId: order_id,
                razorpayOrderId: razorpay_order_id,
                razorpayPaymentId: razorpay_payment_id,
                razorpaySignature: razorpay_signature,
              }
            );

            if (paymentResult.status === 200) {
              alert("Payment successful!");
            } else {
              alert("Payment failed. Please try again.");
            }
          } catch (error) {
            alert("Payment verification failed. Please try again.");
            console.error(error);
          }
        },
        prefill: {
          name: user.first_name + " " + user.last_name,
          email: user.email,
          contact: user.mobileNo,
        },
        notes: {
          address: "Dlf Corporate Greens, Gurugram",
        },
        theme: {
          color: "#3399cc",
        },
      };

      const rzp1 = new window.Razorpay(options);

      // Event listener for payment failure
      rzp1.on("payment.failed", (response) => {
        alert("Payment failed. Please try again.");
        console.error("Payment failed:", response.error);
      });

      rzp1.open();
      saveInfo(values)
    } catch (error) {
      alert("Order creation failed. Please try again.");
      console.error("Order creation failed:", error);
    }
  };

  useEffect(() => {
    if (!fileUpload) {
      setPreview(undefined);
      return;
    }

    const objectUrl = URL.createObjectURL(fileUpload);
    setPreview(objectUrl);

    // free memory when ever this component is unmounted
    return () => URL.revokeObjectURL(objectUrl);
  }, [fileUpload]);

  const handleGoals = (e) => {
    e.preventDefault();
    var target = e.target;

    var parent = target.parentElement; //parent of "t
    if (e.target.checked) {
      parent.classList.add("selected");
    } else {
      parent.classList.remove("selected");
    }
  };
 
  const saveInfo = async (values) => {
    let formData = new FormData();
    const configr = {
      headers: { "content-type": "multipart/form-data" },
    };

    const textFields = {
      first_name: user.first_name,
      last_name: user.last_name,
      email: user.email,
      company_name: user.company_name,
      mobileNo: user.mobileNo,
      password: user.password,
      IsLogo: values.IsLogo,
      IsStock_image: values.IsStock_image,
      brand_guidlines: values.brand_guidlines,
      brand_name: values.brand_name,
      campaign_industry: values.campaign_industry,
      campaign_name: values.campaign_name,
      gif: values.gif,
      marketing_budget: values.marketing_budget,
      static_meme: values.static_meme,
      time_limit: values.time_limit,
      video_meme: values.video_meme,
      file: fileUpload,
    };

    for (const key in textFields) {
      formData.append(key, textFields[key]);
    }

    try {
      const res = await axios.post(
        `${config.API_URL}/brand/saveinfo`,
        formData,
        configr
      );
      if (res.status === 200) {
        dispatch(setUserID(res.data.userId));
        let USER = {
          first_name: user.first_name,
          email: user.email,
          isLoggin: true,
        };
        sessionStorage.setItem("USER", JSON.stringify(USER));
        navigate("/dashboard/Dashboard");
        window.location.reload(false);
      }
    } catch (error) {
      console.error("Error saving info:", error);
    }
  };
  // const handleEstimation = (e) => {
  //   const allElements = document.querySelectorAll(".estimation label");
  //   allElements.forEach((element) => {
  //     element.classList.remove("selected");
  //   });
  //   var target = e.target;
  //   var parent = target.parentElement; //parent of "t
  //   parent.classList.add("selected");
  // };
  const onSubmit = async (values, { setSubmitting }) => {
    console.log("Sushmaaa");
    console.log(user)
    if (
      user.first_name &&
      user.last_name &&
      user.email &&
    
      user.mobileNo &&
    
      values.IsLogo !== null &&
      values.IsStock_image !== null &&
      values.brand_guidlines !== null &&
      values.brand_name !== null &&
      values.campaign_industry !== null &&
      values.campaign_name !== null &&
      values.gif !== null &&
      values.marketing_budget !== null &&
      values.static_meme !== null &&
      values.time_limit !== null &&
      values.video_meme !== null &&
      fileUpload !== null
    
  ) {
     
      console.log('All values are valid.');
      try {
       
        handlePayment(((values.gif*700) + (values.static_meme*500) + (values.video_meme*1000))*100,values)
      // saveInfo(values)
       

       
      } catch (err) {
        setShow({
          show: true,
          msg: "Some Issue",
        });
      }
  } else {
    setShow({
      show: true,
      msg: "Fill all details ",
    });
    alert("All Fields are required")
  
      console.log('Some values are missing or invalid.');
  }
  

  };
  return (
    <FormikStepper
     
      onSubmit={onSubmit} 
      initialValues={{
        marketing_budget: "Less than 2 lacs",
        marketing_goals: ["Brand Awareness"],
        IsStock_image: "Yes",
        IsLogo: "Yes",
      }}
    
      withStepperLine={false} 
      submitButton={{ label: `Proceed to Pay ` }}
      nextButton={{ label: "Next" }}
      prevButton={{ label: "Back" }}
     
    >
      {/*  First Step */}
      <FormikStep>
        <Container>
          <Row className="justify-content-center">
            <Col md={6} sm={12}>
              <div className="form-header-content">
                <span>Step 1 of 3</span>
                <h2 className="stepper-form-heading">
                  What is your estimated monthly marketing budget?
                </h2>
                <h6>Select an estimated range</h6>
              </div>
              <div
                role="group"
                className="estimation"
                aria-labelledby="my-radio-group"
               
              >
                <label className="selected">
                  <Field
                    type="radio"
                    name="marketing_budget"
                    value="Less than 2 lacs"
                  />
                  Less than 2 lacs
                </label>
                <label>
                  <Field
                    type="radio"
                    name="marketing_budget"
                    value="2 lacs - 8 lacs"
                  />
                  2 lacs - 8 lacs
                </label>

                <label>
                  <Field
                    type="radio"
                    name="marketing_budget"
                    value="8 lacs - 50 lacs "
                  />
                  8 lacs - 50 lacs
                </label>
                <label>
                  <Field
                    type="radio"
                    name="marketing_budget"
                    value="50 lacs & above"
                  />
                  50 lacs & above
                </label>
                <label>
                  <Field
                    type="radio"
                    name="marketing_budget"
                    value="Preferred not to disclose"
                  />
                  Preferred not to disclose
                </label>
              </div>
            </Col>
          </Row>
        </Container>
      </FormikStep>
      {/* Second Step */}

      <FormikStep>
        <ToastComp show={show} onClose={toggleShow} />
        <Container>
          <Row className="justify-content-center">
            <Col md={6} sm={12}>
              <div className="form-header-content">
                <span>Step 2 of 3</span>
                <h2>What are your primary content marketing goals?</h2>
                <h6>Select your goals</h6>
              </div>
              <div className="form-goals">
                <div
                  role="group"
                  aria-labelledby="checkbox-group"
                  onChange={(e) => handleGoals(e)}
                >
                  <label className="selected">
                    <Field
                      type="checkbox"
                      name="marketing_goals"
                      value="Brand Awareness"
                    />
                    Brand Awareness
                  </label>
                  <label>
                    <Field
                      type="checkbox"
                      name="marketing_goals"
                      value="Lead Generation"
                    />
                    Lead Generation
                  </label>
                  <label>
                    <Field
                      type="checkbox"
                      name="marketing_goals"
                      value="Customer Loyalty"
                    />
                    Customer Loyalty
                  </label>
                </div>
              </div>

              <div className="form-campaign">
                <div className="form-header-content">
                  <h2>Enter Campaign Details</h2>
                </div>
                <InputField
                  name="brand_name"
                  placeholder="Brand Name"
                  type="text"
                />
                <InputField
                  name="campaign_name"
                  placeholder="Campaign Name"
                  type="text"
                  required
                />
                <div className="file-upload ">
                  <div>
                    <p>Upload Logo</p>
                    <input
                      type="file"
                      onChange={(event) => {
                        const file = event.target.files[0];
                        const maxSize = 5 * 1024 * 1024; // 5MB in bytes

                        if (file && file.size <= maxSize) {
                          setFileUpload(file);

                          // Optional: Show a preview if it's an image
                          const reader = new FileReader();
                          reader.onload = () => {
                            setPreview(reader.result);
                          };
                          reader.readAsDataURL(file);
                        } else {
                          alert("File size exceeds 5MB limit");
                          event.target.value = null; // Reset the input field
                        }
                      }}
                      required
                    />
                  </div>
                  <div>{preview && <img src={preview} alt="Preview" />}</div>
                </div>

                <Field
                  as="select"
                  name="campaign_industry"
                  placeholder="Choose the industry of your project"
                >
                  <option value={""} disabled selected>
                    Choose the industry of your project
                  </option>
                  {IndustryOptions.map((i) => (
                    <option value={i.value}>{i.value}</option>
                  ))}
                </Field>
                {/* <SelectField
                                name="industry"
                                placeholder="Choose the industry of your project"
                                options={IndustryOptions}
                            /> */}
                <Field
                  name="target_audience"
                  as="textarea"
                  placeholder="What’s your target audience?"
                ></Field>
              </div>
              <div className="form-logo">
                <p>Do you need your logo on design?</p>

                <div role="group" aria-labelledby="my-radio-group">
                  <label>
                    <Field type="radio" name="IsLogo" value="Yes" />
                    Yes
                  </label>
                  <label>
                    <Field type="radio" name="IsLogo" value="No" />
                    No
                  </label>
                </div>
                <p>Can Designer Use Stock Images?</p>
                <div role="group" aria-labelledby="my-radio-group">
                  <label>
                    <Field type="radio" name="IsStock_image" value="Yes" />
                    Yes
                  </label>
                  <label>
                    <Field type="radio" name="IsStock_image" value="No" />
                    No
                  </label>
                </div>
                <Field
                  name="brand_guidlines"
                  as="textarea"
                  placeholder="Brand Guidelines"
                ></Field>
              </div>
            </Col>
          </Row>
        </Container>
      </FormikStep>
      <FormikStep>
        <Container>
          <Row className="justify-content-center">
            <Col md={6} sm={12}>
              <div className="form-header-content">
                <span>Step 3 of 3</span>
                <h2>Which Meme formats are you looking for?</h2>
              </div>
              <div className="form-meme">
                <InputField
                  name="static_meme"
                  label="Static Meme"
                  placeholder="N * 500"
                  type="number"
                  min="1"
                  // style={{width:500}}
                />
                <InputField
                  name="gif"
                  label="Gif Meme"
                  type="number"
                  placeholder="N * 700"
                  min="1"
                />
                <InputField
                  name="video_meme"
                  label="Video Meme"
                  type="number"
                  placeholder="N * 1000"
                  min="1"
                />
              </div>

              <div className="form-campaign " style={{ marginTop: 50 }}>
                <InputField
                  name="time_limit"
                  label="Time Limit"
                  type="number"
                  placeholder="No. of Days"
                  min="1"
                />
              </div>
            </Col>
          </Row>
        </Container>
      </FormikStep>
    </FormikStepper>
  );
};

export const StepperForm = () => {
  return (
    <div className="stepper-form">
      <Container>
        <Row>
          <Col sm={12}>
            <Form />
          </Col>
        </Row>
      </Container>
    </div>
  );
};
