// App.js

import React, { useState, useEffect } from "react";
import axios from "axios";
import { Link, useLocation } from "react-router-dom";
import { config } from "../components/Constant";
import { Alert } from "bootstrap";

function ViewApplicant() {

    const location = useLocation();
    
    // Parse the query parameters
    const queryParams = new URLSearchParams(location.search);
    const Id = queryParams.get('Id');
  const [data, setData] = useState([]);

  // Fetch data from backend when the component mounts
  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    console.log('ID issss',Id)
    try {
      const response = await axios.get(`${config.API_URL}/app/user/getBrandCampaign`,{
        params:{Id}
      });
      console.log( "response iss",response.data.campaigndetails.data);
      setData(response.data.campaigndetails.data);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };


  return (
    <div>
      <h1>List of Applicants</h1>
      {/* <button onClick={handleBulkStatusUpdate}>Change Status of All</button> */}
      <table className="table table-hover">
        <thead>
          <tr>
            <th>Sr No</th>
            <th>userName</th>
            {/* <th>Date_Time</th>
            <th>Category</th>
            <th>Caption</th>
            <th>Post</th>
            <th>Action</th> */}
            <th>File </th>
          </tr>
        </thead>
        <tbody>
          {data.map((data, index) => {
            return (
              <tr key={index}>
                <td>{index + 1}</td>
                <td>{data.userName}</td>
                <td>
                  {" "}
                  <Link
                    to={`https://www.adoro.social/Campaign/${data.fileName}`}
                  >
                    {data.type}
                  </Link>{" "}
                </td>
                {/* <td>{data.date}</td>
                <td> {data.category} </td>

                <td>
                  <div
                    style={{
                      marginTop: "15px",
                      maxWidth: "300px",
                      wordWrap: "break-word",
                    }}
                  >
                    {data.content}
                  </div>
                </td>
                <td>
                  {" "}
                  <Link
                    to={`https://www.adoro.social/UserPost/${data.fileName}`}
                  >
                    {data.type}
                  </Link>{" "}
                </td>
                <td>
                  <i
                    className="fa fa-check"
                    aria-hidden="true"
                    // onClick={() => handleStatusApprove(data.Id, "approve")}
                  ></i>
                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                  <i
                    className="fa fa-times"
                    aria-hidden="true"
                    // onClick={() => handleStatusReject(data.Id, "reject")}
                  ></i>
                </td> */}
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
}

export default ViewApplicant;
